import Cookies from 'js-cookie'

export default defineNuxtRouteMiddleware(async to => {
  const userStore = useUserStore()

  if (userStore.email.endsWith('@skribble.com')) {
    Cookies.set('skribble_internal', 'true')
  }

  const systemStore = useSystemStore()

  await systemStore.checkMaintenance()

  if (systemStore.activeMaintenance?.isBlocking && to.path !== '/maintenance') {
    if (Cookies.get('skribble_internal') === 'true') return
    return navigateTo('/maintenance')
  }
})
