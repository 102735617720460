<script lang="ts">
import ProgressSteps from '@/components/ProgressSteps.vue'
import BigButtonGroup from '@/components/BigButtonGroup.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import SkrCheckbox from '@/components/SkrCheckbox.vue'
import ToolbarBase from '@/components/ToolbarBase.vue'
import SkrHeading from '@/components/SkrHeading.vue'
import SkrButton from '@/components/button/SkrButton.vue'
import TwoColsBlock from '@/components/layout/TwoColsBlock.vue'

export default defineComponent({
  components: {
    TwoColsBlock,
    ToolbarBase,
    SkrButton,
    SkrHeading,
    ResponsiveImage,
    ProgressSteps,
    SkrCheckbox,
    BigButtonGroup,
  },
  setup() {
    definePageMeta({
      layout: false,
      accessControl: {
        roles: ['user'],
        isStrict: true,
      },
    })

    const { t } = useI18n()

    const businessStore = useBusinessStore()
    const userStore = useUserStore()
    const widgetStore = useWidgetStore()

    const { isTrue, isString, isPhoneNumber } = useValidation()

    return {
      userEmail: userStore.email,
      fetchUser: userStore.fetchUser,
      registerBusiness: businessStore.registerBusiness,
      t,
      isTrue,
      isString,
      isPhoneNumber,
      widgetStore,
    }
  },
  data() {
    return {
      currentStep: 1,
      isLoading: false,
      isFormValid: false,
      registerInfo: {
        name: '',
        phone: '',
        gtcAccepted: false,
        attributes: {
          companySize: '',
          skribbleSigningVolume: '',
        },
      },
    }
  },
  head() {
    return {
      title: 'Business - Register',
    }
  },
  computed: {
    peopleSurvey() {
      return [
        { text: '1', value: 'Self-employed' },
        { text: '2-10', value: '1' },
        { text: '11-50', value: '2' },
        { text: '51-200', value: '51-200' },
        { text: '201+', value: '3' },
      ]
    },
    usageSurvey() {
      return [
        { text: this.t('business.signup.survey.usage.options.seldom'), value: 'Seldom' },
        { text: this.t('business.signup.survey.usage.options.often'), value: 'Often' },
        { text: this.t('business.signup.survey.usage.options.invited'), value: 'Invited' },
      ]
    },
  },
  methods: {
    selectPeople(index: number) {
      this.registerInfo.attributes.companySize = this.peopleSurvey[index].value
      this.currentStep += 1
    },
    selectUsage(index: number) {
      this.registerInfo.attributes.skribbleSigningVolume = this.usageSurvey[index].value
      this.currentStep += 1
    },
    async register() {
      if (!this.userEmail) return
      this.isLoading = true

      try {
        await this.registerBusiness({
          email: this.userEmail,
          ...this.registerInfo,
        })
        await this.fetchUser()
        await navigateTo({
          name: 'business-welcome',
          params: { origin: 'register' },
        })
      } catch {
        this.widgetStore.createSnackbar({
          message: this.t('global.error.generic_support'),
        })
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>

<template>
  <v-main>
    <toolbar-base
      :title="t('business.register.header.title')"
      :is-closeable="true"
      app
      @close="navigateTo({ path: '/' })"
    >
      <template #options>
        <div class="px-8">
          <progress-steps :num-of-steps="3" :current-step="currentStep" />
        </div>
      </template>
    </toolbar-base>
    <two-cols-block class="fill-height" split="5/7">
      <template #left>
        <v-fade-transition v-if="currentStep === 1" hide-on-leave appear>
          <div>
            <div class="mb-12">
              <skr-heading level="1" semantic>{{ t('business.signup.survey.people.title') }}</skr-heading>
            </div>
            <big-button-group :values="peopleSurvey.map(item => item.text)" @click="selectPeople" />
          </div>
        </v-fade-transition>
        <v-fade-transition v-if="currentStep === 2" hide-on-leave appear>
          <div>
            <div class="mb-12">
              <skr-heading level="1" semantic>{{ t('business.signup.survey.usage.title') }}</skr-heading>
            </div>
            <big-button-group :values="usageSurvey.map(item => item.text)" stacked @click="selectUsage" />
            <div class="text-center mt-6">
              <skr-button type="text" size="lg" block @click="currentStep -= 1">
                {{ t('global.back') }}
              </skr-button>
            </div>
          </div>
        </v-fade-transition>
        <v-fade-transition v-if="currentStep === 3" hide-on-leave appear>
          <div>
            <div class="mb-12">
              <skr-heading level="1" semantic>{{ t('business.register.title') }}</skr-heading>
              <skr-heading level="2">{{ t('business.signup.subtitle') }}</skr-heading>
            </div>
            <v-form v-model="isFormValid">
              <v-text-field
                v-model="registerInfo.name"
                data-cy="businessRegisterCompanyName"
                autocomplete="given-name"
                hide-details
                :label="t('global.company_name')"
                name="fname"
                variant="filled"
                :rules="[isString]"
                class="mb-4"
              />
              <v-text-field
                v-model="registerInfo.phone"
                data-cy="businessRegisterCompanyPhone"
                autocomplete="tel"
                :label="t('global.phone_input.label')"
                persistent-hint
                name="phone"
                variant="filled"
                :rules="[isPhoneNumber]"
              />
              <skr-checkbox v-model="registerInfo.gtcAccepted" class="mb-4 mt-2" data-cy="register_checkbox" required>
                <i18n-t keypath="business.setup.gtc.label_text" tag="div" for="business.setup.gtc.label_link_text">
                  <a :href="t('business.setup.gtc.label_link_url')" target="_blank" rel="noopener" @click.stop>{{
                    t('business.setup.gtc.label_link_text')
                  }}</a>
                </i18n-t>
              </skr-checkbox>
            </v-form>
            <skr-button
              block
              size="xl"
              data-cy="signup_submit_button"
              :disabled="!isFormValid || isLoading"
              :loading="isLoading"
              @click="register"
            >
              {{ t('business.signup.start') }}
            </skr-button>
            <div class="text-center mt-6">
              <skr-button block type="text" size="lg" @click="currentStep -= 1">
                {{ t('business.setup.billing.back') }}
              </skr-button>
            </div>
          </div>
        </v-fade-transition>
      </template>
      <template v-if="$vuetify.display.mdAndUp" #right>
        <v-fade-transition v-if="currentStep < 3" hide-on-leave appear>
          <responsive-image source="questions" :width="570" :height="472" />
        </v-fade-transition>
        <v-fade-transition v-if="currentStep === 3" hide-on-leave appear>
          <responsive-image source="business-registration-step-2" :width="512" :height="571" />
        </v-fade-transition>
      </template>
    </two-cols-block>
  </v-main>
</template>
