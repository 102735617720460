type Platform = 'CH' | 'EU'

interface MaintenanceResponse {
  page: {
    name: string
    url: string
    status: string
  }
  activeIncidents?: {
    id: string
    name: string
    started: string
    status: string
    impact: string
    url: string
    updatedAt: string
  }[]
  activeMaintenances?: ActiveMaintenance[]
}

interface StatusPageGroup {
  id: string
  name: string
  description?: string
}

interface StatusPageComponent {
  id: string
  name: string
  description?: string
  status: string
  group?: StatusPageGroup
  activeMaintenances?: ActiveMaintenance[]
}

interface ComponentsResponse {
  components: StatusPageComponent[]
}

export const useSystemStore = defineStore('system', () => {
  const { $config } = useNuxtApp()

  const domainName = computed<string>(() => $config.public.domainName)

  const platform = computed<Platform>(() => {
    if (/\.(de|eu)$/.test(domainName.value)) {
      return 'EU'
    } else return 'CH'
  })

  const emails = reactive({
    support: 'support@' + domainName.value,
    contact: 'contact@' + domainName.value,
    success: 'success@' + domainName.value,
  })

  const redirectUrls = reactive({
    homepage: 'https://' + domainName.value,
  })

  const time = reactive({
    now: '',
    lastUpdated: 0,
  })

  const updateTime = async () => {
    if ((Date.now() - time.lastUpdated) * 0.001 < 2) return

    const { systemRepository } = useApi()

    const { now } = await systemRepository.getDateTime()
    time.now = now
    time.lastUpdated = Date.now()
  }

  const roleHierarchy: AppRole[] = ['unauthenticated', 'nas', 'user', 'member', 'admin']

  const activeMaintenance = shallowRef<ActiveMaintenance>()

  const { instatus } = useRuntimeConfig().public

  const { logError } = useErrorHandling()

  const maintenanceInterval = ref(0)

  watch(activeMaintenance, maintenance => {
    if (maintenance) {
      maintenanceInterval.value = maintenanceInterval.value || window.setInterval(checkMaintenance, 60000)
    } else {
      window.clearInterval(maintenanceInterval.value)
      maintenanceInterval.value = 0
    }
  })

  const checkMaintenance = async () => {
    if (!instatus?.url) return

    const blockingGroupRegex = instatus.blockingGroupPattern ? new RegExp(instatus.blockingGroupPattern) : null

    try {
      const { activeMaintenances } = await $fetch<MaintenanceResponse>(`${instatus.url}/summary.json`)
      const maintenance = activeMaintenances?.find(maintenance => maintenance.status === 'INPROGRESS')
      if (maintenance) {
        const { components } = await $fetch<ComponentsResponse>(`${instatus.url}/v2/components.json`)
        const affectedComponents =
          components?.filter(component =>
            Boolean(
              component.activeMaintenances?.find(componentMaintenance => componentMaintenance.id === maintenance.id)
            )
          ) ?? []
        const isBlocking = affectedComponents.some(component =>
          Boolean(component.group && blockingGroupRegex?.test(component.group.name))
        )

        activeMaintenance.value = {
          ...maintenance,
          isBlocking,
        }
      } else {
        // Trigger shallow ref update to clear potential active maintenance
        activeMaintenance.value = maintenance
      }
    } catch (error) {
      logError('Failed to fetch status page info', error)
    }
  }

  return {
    emails,
    redirectUrls,
    platform,
    time,
    roleHierarchy,
    activeMaintenance,
    checkMaintenance,
    updateTime,
  }
})
